import { Grid, TablePagination, Typography } from '@material-ui/core';
import React from 'react';
import Button from '../UI/Button';
import TablePaginationActions from '../../shared/components/Table/Actions';

const VotacaoTable = ({ pageVotacao, handleChangePage, setVotacao }) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item>
                    <Button
                        onClick={() => setVotacao({ eNovo: true })}
                    >
                        Nova Votação
                    </Button>
                </Grid>
                {pageVotacao.totalElements > 0 ?
                    <>
                        {pageVotacao.content.map((votacao) => (
                            <Grid item xs={12}
                                style={{
                                    border: '1px solid black',
                                    borderRadius: '20px'
                                }}
                            >
                                <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                                    <Grid item xs={4}>
                                        <Typography variant='h6'>Título</Typography>
                                        <Typography variant='body1'>
                                            {votacao?.titulo}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            onClick={() => setVotacao(votacao)}
                                        >
                                            Selecionar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        <TablePagination
                            rowsPerPageOptions={[]}
                            rowsPerPage={pageVotacao.size ? pageVotacao.size : 0}
                            component="div"
                            count={pageVotacao.totalElements}
                            page={pageVotacao.number}
                            onPageChange={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                        />
                    </> :
                    <Grid container justifyContent='center' alignItems='center'>
                        <Grid item>
                            <Typography variant='body1'>Nenhuma Votação cadastrada...</Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>


        </>
    );
}

export default VotacaoTable;