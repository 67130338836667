import React, { useEffect, useState } from 'react';
import { findVotacaoOpcaoByVotacaoCodigo } from './api-votacao-opcao';
import Loading from '../UI/Loading';
import VotacaoOpcaoForm from './VotacaoOpcaoForm';
import VotacaoOpcaoTable from './VotacaoOpcaoTable';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import { Grid } from '@material-ui/core';

const VotacaoOpcaoContainer = ({ votacao }) => {
    const [listVotacaoOpcao, setListVotacaoOpcao] = useState([]);
    const [votacaoOpcao, setVotacaoOpcao] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        findVotacaoOpcaoByVotacaoCodigo(votacao.codigo).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setListVotacaoOpcao(data);
                }
            }
            setIsLoading(false);
        })
    }, [votacao.codigo]);

    return (
        <Grid container spacing={3}>
            {votacaoOpcao ?
                <Grid item xs={12}>
                    <VotacaoOpcaoForm
                        votacaoOpcao={votacaoOpcao}
                        setVotacaoOpcao={setVotacaoOpcao}
                        setListVotacaoOpcao={setListVotacaoOpcao}
                    />
                </Grid> :
                <Grid item xs={12}>
                    {isLoading ?
                        <Loading description="Carregando Opções" /> :
                        <VotacaoOpcaoTable
                            listVotacaoOpcao={listVotacaoOpcao}
                            setVotacaoOpcao={setVotacaoOpcao}
                            votacao={votacao}
                        />
                    }
                </Grid>
            }

        </Grid>
    )
}

export default VotacaoOpcaoContainer;