import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import { findByVotacao, save, remove } from './api-votacao-aluno';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';
import AlunoFindDialog from '../Aluno/AlunoFindDialog';
import { errorMessage } from '../../util/ConfirmAlertUtil';

const useStyles = makeStyles({
    title: {
        paddingTop: '20px'
    },
    novoRegistro: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    }
});


export default function VotacaoAlunoTable({ votacao }) {
    const classes = useStyles();
    const [votacaoAlunos, setVotacaoAlunos] = useState([]);
    const [aluno, setAluno] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (aluno !== null) {
            const newVotacaoAluno = {
                votacao: votacao,
                aluno: aluno
            }
            save(newVotacaoAluno).then((data) => {
                if (data) {
                    if (data.error) {
                        toast.error(`Aluno ${aluno} já cadastrada.`, {
                            position: toast.POSITION.BOTTOM_LEFT
                        });
                    } else {
                        setVotacaoAlunos(prevState => ([
                            ...prevState,
                            data
                        ]));
                    }

                }

            });
            setAluno(null)
        }
    }, [aluno, votacao]);

    useEffect(() => {
        findByVotacao(votacao.codigo).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setVotacaoAlunos(data);
                }
            }
        })
    }, [votacao.codigo]);


    const handleDelete = (index) => {
        if (!votacao.enviado) {
            confirmAlert({
                title: 'Alerta',
                message: 'Deseja deletar este aluno da Votação?',
                buttons: [
                    {
                        label: 'Sim',
                        onClick: () => {
                            remove(votacaoAlunos[index].codigo).then((data) => {
                                if (data && data.error) {
                                    errorMessage(data.message);
                                } else {
                                    setVotacaoAlunos(
                                        votacaoAlunos.filter((va) => va.codigo !== votacaoAlunos[index].codigo)
                                    );

                                }
                            });
                        }
                    },
                    {
                        label: 'Não',
                        onClick: () => { }
                    }
                ]
            });
        }

    };

    return (
        <Container style={{ border: '1px solid black' }}>
            <AlunoFindDialog
                openSearch={open}
                setOpenSearch={setOpen}
                setAluno={setAluno}
            />
            <Typography className={classes.title} variant="h6">
                Votação Aluno
            </Typography>
            <Button
                onClick={() => {
                    setOpen(true);
                }}
                variant="contained"
                className={classes.novoRegistro}
                disabled={votacao.enviado}
                color="primary"
            >
                Adicionar Aluno
            </Button>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Aluno</TableCell>
                        <TableCell>Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {votacaoAlunos.map((va, index) => (
                        <TableRow key={va.codigo}>
                            <TableCell>{va.aluno.nome}</TableCell>
                            <TableCell>
                                <DeleteIcon
                                    fontSize='small'
                                    className={classes.cursorLink}
                                    onClick={() => { handleDelete(index) }}
                                    color="secondary"
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    );
}