import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { findAll } from "../ComunicadoCategoria/api-comunicado-categoria";
import { save, send } from "./api-comunicado";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SendIcon from '@material-ui/icons/Send';
import { confirmAlert } from 'react-confirm-alert';
import SimpleDialogMessage from "../../../util/SimpleDialogMessage/SimpleDialogMessage";
import { ADMIN, COORDENADOR } from '../../Usuario/Perfil';
import SunEditor, { buttonList } from 'suneditor-react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { DateTimePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from "date-fns/locale";
import { decreaseQuantAprovacaoComunicado, increaseQuantAprovacaoComunicado } from './actions/actions';
import { open } from '../../../util/SimpleDialogMessage/actions/actions';
import { FormHelperText, Typography } from '@material-ui/core';
import ComunicadoBannerDialog from '../ComunicadoBanner/ComunicadoBannerDialog';
import { errorMessage } from '../../../util/ConfirmAlertUtil';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '20px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 3),
    },
    send: {
        margin: theme.spacing(3, 0, 3),
        backgroundColor: '#4caf50'
    },
    buscarBtn: {
        margin: theme.spacing(3, 3, 3),
    },
    closeIcon: {
        marginTop: '20px',
        marginBottom: '10px',
        cursor: 'pointer'
    },
    title: {
        marginBottom: '20px'
    },
    criadoPorLabel: {
        fontStyle: 'bold'
    },
    criadoPorValue: {
        fontStyle: 'italic'
    },
    divCriadoPor: {
        margin: '20px',
        padding: '10px',
    },
    agendamentoDiv: {
        border: '1px solid black',
        padding: '20px',
        margin: '20px',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}));

export default function ComunicadoForm({
    comunicado,
    setComunicado,
    updateComunicados,
    addComunicados,
    handleDelete
}) {
    const classes = useStyles();
    const [enviarPor] = useState([
        'Aluno',
        'Turma'
    ]);
    const [enviarPara] = useState([
        'Ambos',
        'Alunos',
        'Responsáveis'
    ]);
    const [categorias, setCategorias] = useState([]);
    const [message, setMessage] = useState({});
    const usuario = useSelector(state => state.usuario);
    const [isPerforming, setIsPerforming] = useState(false);
    const dispatch = useDispatch();
    const [openComunicadoBanner, setOpenComunicadoBanner] = useState(false);


    useEffect(() => {
        findAll().then((data) => {
            setCategorias(data);
        });
    }, []);

    const handleChange = (event) => {
        setComunicado({
            ...comunicado,
            [event.target.name]: event.target.value
        })
    }

    const handleChangeDescricao = (value) => {
        setComunicado(prevState => ({
            ...prevState,
            descricao: value.replace(/[\u200B-\u200D\uFEFF]/g, '')
        }))
    }

    const handleCloseComunicadoBanner = () => {
        setOpenComunicadoBanner(false);
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        setIsPerforming(true);
        const eNovo = comunicado.eNovo;
        if (!comunicado.enviarPara) {
            toast.error("Selecione para quem será enviado o comunicado.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }
        if (!comunicado.categoria) {
            toast.error("Selecione a categoria.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }
        if (eNovo) {
            dispatch(increaseQuantAprovacaoComunicado());
        }
        save(comunicado).then(data => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    toast.success("Salvo com sucesso.", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                    if (eNovo) {
                        addComunicados(data);
                    } else {
                        updateComunicados(data)
                    }
                    setComunicado(data);
                }
            }
            setIsPerforming(false);
        })
    }

    const handleEnviarPorChange = (event) => {
        const ep = enviarPor.find((ep) => {
            return ep === event.target.value
        });
        setComunicado({
            ...comunicado,
            enviarPor: ep
        });
    }

    const handleEnviarParaChange = (event) => {
        const ep = enviarPara.find((ep) => {
            return ep === event.target.value
        });
        setComunicado({
            ...comunicado,
            enviarPara: ep
        });
    }

    const handleCategoriaChange = (event) => {
        const categoria = categorias.find((categoria) => {
            return categoria.codigo === event.target.value
        });
        setComunicado({
            ...comunicado,
            categoria: categoria
        });
    }

    const handleAprove = () => {
        const persist = {
            ...comunicado,
            aprovado: !comunicado.aprovado
        }
        if (persist.aprovado) {
            dispatch(decreaseQuantAprovacaoComunicado());
        } else {
            dispatch(increaseQuantAprovacaoComunicado());
        }
        setIsPerforming(true);
        save(persist).then(data => {
            if (data) {
                let message = '';
                if (data.aprovado) {
                    message = "Comunicado Aprovado com Sucesso.";
                } else {
                    message = "Aprovação Removida com Sucesso.";
                }
                toast.success(message, {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                updateComunicados(data)
                setComunicado(data);
            }
            setIsPerforming(false);
        });
    }

    const handleSend = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja enviar este comunicado? Após confirmado esta operação não poderá ser desfeita.',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setIsPerforming(true);
                        send(comunicado.codigo).then(data => {
                            if (data.error) {
                                if (data.message === 'No turma registered') {
                                    setMessage({
                                        title: 'Comunicado não foi enviado',
                                        description: 'Comunicado não possui turmas cadastradas para o envio.'
                                    });
                                    dispatch(open());

                                } else if (data.message === 'No aluno registered') {
                                    setMessage({
                                        title: 'Comunicado não foi enviado',
                                        description: 'Comunicado não possui alunos cadastrados para o envio.'
                                    });
                                    dispatch(open());

                                }
                            } else {
                                toast.success("Comunicado Enviado com Sucesso.", {
                                    position: toast.POSITION.BOTTOM_LEFT
                                });
                                updateComunicados(data);
                                setComunicado(data);
                            }
                            setIsPerforming(false);
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }



    const handleChangeImage = e => {
        setOpenComunicadoBanner(true);
    };

    const handleSwitchChange = name => event => {
        setComunicado((prevState) => ({
            ...prevState,
            [name]: event.target.checked
        }));
    };

    const handleDataHoraAgendamentoChange = (dataHoraAgendamento) => {

        setComunicado({
            ...comunicado,
            dataHoraAgendamento: dataHoraAgendamento
        })
    }

    const handleDataHoraParaChange = (dataHoraPara) => {

        setComunicado({
            ...comunicado,
            dataHoraPara: dataHoraPara
        })
    }

    const handleComunicadoBanner = (cb) => {
        handleCloseComunicadoBanner();
        setComunicado((prevState) => ({
            ...prevState,
            comunicadoBanner: cb
        }))
    }

    return (
        <div>
            <SimpleDialogMessage title={message.title} description={message.description} />
            <ComunicadoBannerDialog
                open={openComunicadoBanner}
                handleClose={handleCloseComunicadoBanner}
                setComunicadoBanner={handleComunicadoBanner}
            />
            <Container className={classes.paper} component={Paper} >
                <Grid container spacing={4} direction='column'>
                    <Grid item>
                        <CloseIcon
                            onClick={() => setComunicado(null)}
                            className={classes.closeIcon}
                            fontSize='small'
                        />
                    </Grid>
                    <Grid item>

                        <form onSubmit={clickSubmit}>
                            <Grid container spacing={2} direction='column'>
                                <Grid container spacing={2} direction='row'>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth >
                                            <InputLabel id="enviar-por-label">Enviar Por:</InputLabel>
                                            <Select
                                                disabled={comunicado.enviado}
                                                labelId="enviar-por-label"
                                                id="enviar-por-label"
                                                value={
                                                    comunicado.enviarPor ?
                                                        comunicado.enviarPor :
                                                        ''
                                                }
                                                onChange={handleEnviarPorChange}
                                            >
                                                {enviarPor.map((ep, index) => (
                                                    <MenuItem key={index} value={ep}>
                                                        {ep}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth >
                                            <InputLabel id="perfil-label">Enviar Para:</InputLabel>
                                            <Select
                                                disabled={comunicado.enviado}
                                                labelId="enviar-para-label"
                                                id="enviar-para-select"
                                                value={
                                                    comunicado.enviarPara ?
                                                        comunicado.enviarPara :
                                                        ''
                                                }
                                                onChange={handleEnviarParaChange}
                                            >
                                                {enviarPara.map((ep, index) => (
                                                    <MenuItem key={index} value={ep}>
                                                        {ep}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>

                                        <FormControl fullWidth >
                                            <InputLabel id="categoria-label">Categoria:</InputLabel>
                                            <Select
                                                labelId="categoria-label"
                                                id="categoria-select"
                                                value={
                                                    categorias.length > 0 &&
                                                        comunicado.categoria ?
                                                        comunicado.categoria.codigo :
                                                        ''
                                                }
                                                onChange={handleCategoriaChange}
                                            >
                                                {categorias.map((categoria, index) => (
                                                    <MenuItem key={index} value={categoria.codigo}>
                                                        {categoria.descricao}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>




                                <Grid item>
                                    <Grid container spacing={2} direction='column'>
                                        <Grid item>
                                            <Typography variant='body1'>
                                                Banner
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={2} direction='row'>
                                                <Grid item>
                                                    <img alt=''
                                                        src={comunicado.comunicadoBanner ?
                                                            comunicado.comunicadoBanner.urlBanner :
                                                            null}
                                                        style={{ width: '200px' }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Grid container spacing={3} direction='column'>
                                                        <Grid item>
                                                            <Button
                                                                onClick={handleChangeImage}
                                                                variant='outlined'
                                                            >
                                                                Selecionar Banner
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>

                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        size='small'
                                        required
                                        fullWidth
                                        label="Título"
                                        name="titulo"
                                        onChange={handleChange}
                                        inputProps={{ maxLength: 100 }}
                                        value={comunicado && comunicado.titulo ? comunicado.titulo : ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                                        <DateTimePicker
                                            size='small'
                                            ampm={false}
                                            minutesStep={60}
                                            variant="outlined"
                                            margin="normal"
                                            label="Data Hora Para"
                                            value={comunicado && comunicado.dataHoraPara ? comunicado.dataHoraPara : null}
                                            onChange={handleDataHoraParaChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <FormHelperText>Campo destinado para informar Para quando será a entrega da Atividade/Lição.
                                        Caso não possua, manter a data atual.
                                    </FormHelperText>

                                </Grid>
                                <Grid item>
                                    <SunEditor
                                        onChange={handleChangeDescricao}
                                        setContents={comunicado && comunicado.descricao ? comunicado.descricao : ''}
                                        setOptions={{
                                            height: 500,
                                            buttonList: buttonList.complex
                                        }}
                                        setDefaultStyle="font-size: 18px;"
                                    />
                                </Grid>
                                <Grid item>
                                    <div className={classes.agendamentoDiv}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={comunicado && comunicado.agendado ? true : false}
                                                    onChange={handleSwitchChange('agendado')}
                                                    value={comunicado && comunicado.agendado ? true : false}
                                                />
                                            }
                                            label="Agendado"
                                        />

                                        {comunicado && comunicado.agendado ?
                                            <div>
                                                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                                                    <DateTimePicker
                                                        size='small'
                                                        ampm={false}
                                                        minutesStep={60}
                                                        required
                                                        variant="outlined"
                                                        margin="normal"
                                                        label="Data Hora Agendamento"
                                                        value={comunicado && comunicado.dataHoraAgendamento ? comunicado.dataHoraAgendamento : null}
                                                        onChange={handleDataHoraAgendamentoChange}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change time',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            : ''}
                                    </div>
                                </Grid>
                                <Grid item>

                                    {comunicado.usuario ? <div className={classes.divCriadoPor}>
                                        <p className={classes.criadoPorLabel}>Criado por:</p>
                                        <p className={classes.criadoPorValue}>{comunicado.usuario.nome} - {comunicado.usuario.perfil}</p>
                                    </div> : ''}
                                </Grid>

                                <Grid item>

                                    <Grid container justifyContent="space-between">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            disabled={isPerforming}
                                        >
                                            Salvar
                                        </Button>
                                        {comunicado.codigo &&
                                            usuario.instituicao.comunicadoAprovacao &&
                                            (usuario.perfil === ADMIN || usuario.perfil === COORDENADOR) ?
                                            <Button
                                                disabled={comunicado.enviado || isPerforming}
                                                variant="contained"
                                                onClick={handleAprove}
                                                color="secondary"
                                                className={classes.submit}>
                                                {comunicado.aprovado ? 'Remover Aprovação' : 'Aprovar'}
                                            </Button> : null
                                        }
                                        {comunicado && !comunicado.agendado &&
                                            <Button
                                                disabled={
                                                    (usuario.instituicao.comunicadoAprovacao ? !comunicado.aprovado : false) ||
                                                    !comunicado.codigo ||
                                                    isPerforming
                                                }
                                                onClick={handleSend}
                                                startIcon={<SendIcon />}
                                                variant="contained"
                                                className={classes.send}
                                            >
                                                Enviar
                                            </Button>
                                        }

                                    </Grid>
                                </Grid>
                                {comunicado.codigo &&
                                    <Grid item>
                                        <Grid container justifyContent="space-between">
                                            <Button
                                                variant="outlined"
                                                className={classes.submit}
                                                onClick={() => handleDelete(comunicado.codigo)}
                                            >
                                                Remover
                                            </Button>
                                        </Grid>
                                    </Grid>
                                }


                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Container>

        </div>
    );
}