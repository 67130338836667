import { SERVER_URL } from '../../../constants';
import {
  fetchGetAuth,
  fetchPostAuth,
  fetchRemoveAuth
} from '../../../util/FetchUtil';

const controller = 'comunicado/';

const page = (pageNumber) => {
  const url = SERVER_URL + controller + 'page?page=' + pageNumber;
  return fetchGetAuth(url);
}

const save = (comunicado) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, comunicado);
}

const findByTitulo = (titulo, page) => {
  const url = SERVER_URL + controller + 'titulo/' + titulo + '?page=' + page;
  return fetchGetAuth(url);
}

const remove = (codComunicado) => {
  const url = SERVER_URL + controller + codComunicado;
  return fetchRemoveAuth(url);
}

const findByCategoria = (codCategoria, page) => {
  const url = SERVER_URL + controller + 'categoria/' + codCategoria + '?page=' + page;
  return fetchGetAuth(url);
}

export const findComunicadoByUsuario = (codUsuario, page) => {
  const url = SERVER_URL + controller + 'page-query?cod-usuario=' + codUsuario + '&page=' + page;
  return fetchGetAuth(url);
}

const send = (codComunicado) => {
  const url = SERVER_URL + controller + 'send/' + codComunicado;
  return fetchGetAuth(url);
}

const getQuantAprovacaoComunicado = () => {
  const url = SERVER_URL + controller + 'aprovacao';
  return fetchGetAuth(url);
}

export {
  page,
  save,
  findByTitulo,
  remove,
  findByCategoria,
  send,
  getQuantAprovacaoComunicado
}