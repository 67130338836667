import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../shared/components/Table/Actions';
import Button from '../UI/Button';
import Loading from '../UI/Loading';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    novoRegistro: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    cursorLink: {
        cursor: 'pointer'
    },
    rowHeader: {
        fontSize: 13,
        fontWeight: '700',
    },
});


const InstituicaoTable = ({ pageInstituicoes, handleChangePage, setInstituicao, isLoading }) => {
    const classes = useStyles();

    return (
        <div>
            {isLoading ? <Loading description="Carregando Instituições..." /> :
                <>
                    <Button
                        onClick={() => setInstituicao({ eNovo: true })}
                        className={classes.novoRegistro}
                    >
                        Novo Aluno
                    </Button>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.rowHeader}>Descrição</TableCell>
                                <TableCell className={classes.rowHeader}>CPF / CNPJ</TableCell>
                                <TableCell className={classes.rowHeader}>Endereço</TableCell>
                                <TableCell className={classes.rowHeader}>Bairro</TableCell>
                                <TableCell className={classes.rowHeader}>Cidade</TableCell>
                                <TableCell className={classes.rowHeader}>Estado</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pageInstituicoes.content.map((instituicao) => (
                                <TableRow key={instituicao.codigo}>
                                    <TableCell>{instituicao.descricao ? instituicao.descricao : ''}</TableCell>
                                    <TableCell>{instituicao.cpfCnpj ? instituicao.cpfCnpj : ''}</TableCell>
                                    <TableCell>{instituicao.endereco ? instituicao.endereco : ''}</TableCell>
                                    <TableCell>{instituicao.bairro ? instituicao.bairro : ''}</TableCell>
                                    <TableCell>{instituicao.cidade ? instituicao.cidade : ''}</TableCell>
                                    <TableCell>{instituicao.estado ? instituicao.estado : ''}</TableCell>
                                    <TableCell>
                                        <Button
                                            fontSize='small'
                                            className={classes.cursorLink}
                                            onClick={() => setInstituicao(instituicao)}
                                            color="secondary"
                                            style={{ marginRight: '10px' }} >
                                            Selecionar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        rowsPerPage={pageInstituicoes.size ? pageInstituicoes.size : 0}
                        component="div"
                        count={pageInstituicoes.totalElements}
                        page={pageInstituicoes.number}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                    />
                </>
            }


        </div>
    );
}

export default InstituicaoTable;