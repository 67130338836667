import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import Button from '../UI/Button';

const VotacaoOpcaoTable = ({ listVotacaoOpcao, setVotacaoOpcao, votacao }) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item>
                    <Button
                        onClick={() => setVotacaoOpcao({
                            eNovo: true,
                            votacao: votacao
                        })}
                    >
                        Nova Votação Opção
                    </Button>
                </Grid>
                {listVotacaoOpcao.length > 0 ?
                    <>
                        {listVotacaoOpcao.map((vo) => (
                            <Grid item xs={12}
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '20px',
                                    marginTop: '10px',
                                    marginBottom: '10px',

                                }}
                            >
                                <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                                    <Grid item xs={4}>
                                        <Typography variant='body1'>
                                            {vo?.descricao}
                                        </Typography>
                                    </Grid>
                                    {vo.possuiImagem &&
                                        <Grid item xs={4}>
                                            <img alt='' src={vo.urlImagem} style={{ width: '200px' }} />
                                        </Grid>
                                    }

                                    <Grid item xs={4}>
                                        <Button
                                            onClick={() => setVotacaoOpcao(vo)}
                                        >
                                            Selecionar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}

                    </> :
                    <Grid container justifyContent='center' alignItems='center'>
                        <Grid item>
                            <Typography variant='body1'>Nenhuma Opção cadastrada...</Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>


        </>
    );
}

export default VotacaoOpcaoTable;