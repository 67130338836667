import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { CardActions } from '@material-ui/core';
import Button from '../UI/Button';
import { dateTimeFormat } from '../../util/DateUtil';

const useStyles = makeStyles({
    card: {
        margin: 'auto',
        width: '80%',
    },
    title: {
        fontSize: 25,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function VotacaoUsuarioProfile({ votacaoUsuario, handleTrocaVotacaoUsuario }) {
    const classes = useStyles();

    return (
        <Card className={classes.card} variant="outlined">
            <CardContent>
                <Grid container justifyContent='space-between' spacing={3}>
                    <Grid item xs={12} md={6} lg={4} justifyContent='center'>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Título
                        </Typography>
                        <Typography variant="body1" component="h2">
                            {votacaoUsuario?.votacao.titulo ? votacaoUsuario?.votacao.titulo : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} justifyContent='center'>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Descrição
                        </Typography>
                        <Typography variant="body1" component="h2">
                            {votacaoUsuario?.votacao.descricao ? votacaoUsuario?.votacao.descricao : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} justifyContent='center'>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Quant. Máx. Seleção
                        </Typography>
                        <Typography variant="body1" component="h2">
                            {votacaoUsuario?.votacao.quantUsuarioOpcao ? votacaoUsuario?.votacao.quantUsuarioOpcao : ''}
                        </Typography>
                    </Grid>
                    {votacaoUsuario.votacao.visulizarResultadoAPartirDe &&
                        <Grid item xs={12} md={6} lg={4} justifyContent='center'>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Data Visualizar Resultados
                            </Typography>
                            <Typography variant="body1" component="h2">
                                {votacaoUsuario?.votacao.dataHoraVisualizarResultadoAPartirDe ? dateTimeFormat(votacaoUsuario?.votacao.dataHoraVisualizarResultadoAPartirDe) : ''}
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <CardActions>
                            <Button
                                onClick={handleTrocaVotacaoUsuario}
                                size="small"
                                color="secondary"
                                variant="contained"
                            >Trocar Votação</Button>
                        </CardActions>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    );
}