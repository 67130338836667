import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import VotacaoUsuarioTable from './VotacaoUsuarioTable';
import { findVotacaoUsuarioByCurrentUser } from './api-votacao-usuario';
import Container from '../UI/Container';
import Loading from '../UI/Loading';
import VotacaoOpcaoRespostaContainer from '../VotacaoOpcaoResposta/VotacaoOpcaoRespostaContainer';
import VotacaoUsuarioProfile from './VotacaoUsuarioProfile';

const VotacaoUsuarioContainer = () => {

    const [pageVotacaoUsuario, setPageVotacaoUsuario] = useState({
        content: [],
        size: 0,
        number: 0,
        totalElements: 0
    });
    const [votacaoUsuario, setVotacaoUsuario] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchDataVotacaoUsuario(0);
    }, []);

    const fetchDataVotacaoUsuario = (newPage) => {
        setIsLoading(true);
        findVotacaoUsuarioByCurrentUser(newPage).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setPageVotacaoUsuario(data);
                }
            }
            setIsLoading(false);
        })
    }

    const handleChangePage = (event, newPage) => {
        fetchDataVotacaoUsuario(newPage);

    };

    const handleTrocaVotacaoUsuario = () => {
        setVotacaoUsuario(null);
    }

    const updateVotacaoUsuario = (vu) => {
        setVotacaoUsuario(vu);
        setPageVotacaoUsuario((prevState) => ({
            ...prevState,
            content: prevState.content.map((ps) => {
                if (ps.codigo === vu.codigo) {
                    return vu;
                } else {
                    return ps;
                }
            })
        }))
    }

    return (
        <Container >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Votação Usuário
                    </Typography>
                </Grid>

                {votacaoUsuario ?
                    <>
                        <Grid item xs={12}>
                            <VotacaoUsuarioProfile
                                votacaoUsuario={votacaoUsuario}
                                handleTrocaVotacaoUsuario={handleTrocaVotacaoUsuario}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <VotacaoOpcaoRespostaContainer
                                votacaoUsuario={votacaoUsuario}
                                setVotacaoUsuario={setVotacaoUsuario}
                                setPageVotacaoUsuario={setPageVotacaoUsuario}
                                updateVotacaoUsuario={updateVotacaoUsuario}
                            />
                        </Grid>

                    </> :
                    <Grid item xs={12}>
                        {isLoading ? <Loading description="Carregando votações..." /> :
                            <VotacaoUsuarioTable
                                pageVotacaoUsuario={pageVotacaoUsuario}
                                handleChangePage={handleChangePage}
                                setVotacaoUsuario={setVotacaoUsuario}
                            />
                        }

                    </Grid>

                }


            </Grid>
        </Container>
    )
}

export default VotacaoUsuarioContainer;