import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import Button from '../UI/Button';
import { green } from '@material-ui/core/colors';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import { dateTimeFormat } from '../../util/DateUtil';

const VotacaoOpcaoRespostaTableSelect = ({ votacaoUsuario, listVotacaoOpcao, listVotacaoOpcaoResposta, 
    setListVotacaoOpcaoResposta, handleSalvarResposta }) => {

    const handleSelectOption = (vo) => {
        if (listVotacaoOpcaoResposta.length === vo.votacao.quantUsuarioOpcao) {
            errorMessage("Pode selecionar apenas " + vo.votacao.quantUsuarioOpcao + " opção(ões)");
            return;
        }
        setListVotacaoOpcaoResposta((prevState) => ([{ votacaoOpcao: vo }, ...prevState]));
    }

    return (
        <>
            <Grid container spacing={3}>
                {listVotacaoOpcao.length > 0 ?
                    <>
                        {listVotacaoOpcao.map((vo) => (
                            <Grid item xs={12}
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '20px',
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    backgroundColor: listVotacaoOpcaoResposta.some((vor) => vor.votacaoOpcao.codigo === vo.codigo) ? green[500] : null

                                }}
                            >
                                <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                                    <Grid item xs={4}>
                                        <Typography variant='body1'>
                                            {vo?.descricao}
                                        </Typography>
                                    </Grid>
                                    {vo.possuiImagem &&
                                        <Grid item xs={4}>
                                            <img alt='' src={vo.urlImagem} style={{ width: '200px' }} />
                                        </Grid>
                                    }
                                    {!votacaoUsuario.respondido &&
                                        <>
                                            {listVotacaoOpcaoResposta.some((vor) => vor.votacaoOpcao.codigo === vo.codigo) ?
                                                <>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            onClick={() => setListVotacaoOpcaoResposta((prevState) => prevState.filter((ps) => ps.votacaoOpcao.codigo !== vo.codigo))}
                                                        >
                                                            Retirar
                                                        </Button>
                                                    </Grid>
                                                </> :
                                                <>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            onClick={() => handleSelectOption(vo)}
                                                        >
                                                            Selecionar
                                                        </Button>
                                                    </Grid>
                                                </>
                                            }
                                        </>
                                    }
                                    {vo.possuiMaxEscolha &&
                                        <Grid item xs={12}>
                                            <Typography variant='body1'>
                                                Quant. Máx Escolha: {vo.quantMaxEscolha}
                                            </Typography>
                                        </Grid>
                                    }


                                    <Grid item xs={12}>

                                        <Typography variant='body1'>
                                            {vo.votacao.visualizarResultadoAtual || new Date(vo.votacao.dataHoraVisualizarResultadoAPartirDe).getTime() < new Date().getTime() ?
                                                <>
                                                    Quant. Já Votado: {vo.quantJaVotado}
                                                </> :
                                                <>
                                                    Resultado liberado em {dateTimeFormat(vo.votacao.dataHoraVisualizarResultadoAPartirDe)}
                                                </>
                                            }

                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                        ))}

                    </> :
                    <Grid container justifyContent='center' alignItems='center'>
                        <Grid item>
                            <Typography variant='body1'>Nenhuma Opção cadastrada...</Typography>
                        </Grid>
                    </Grid>
                }
                {!votacaoUsuario.respondido &&
                    <Grid item xs={12}>
                        <Button onClick={handleSalvarResposta}>Salvar</Button>
                    </Grid>
                }
            </Grid >


        </>
    );
}

export default VotacaoOpcaoRespostaTableSelect;