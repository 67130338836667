import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth
} from '../../util/FetchUtil';

const controller = 'votacao-usuario';

export const findVotacaoUsuarioByCurrentUser = (pageNumber) => {
  const url = SERVER_URL + controller + '/page-query?page=' + pageNumber + '&current-user=true';
  return fetchGetAuth(url);
}
