import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import { findByVotacao, save, remove } from './api-votacao-turma';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import TurmaSelectDialog from '../Turma/TurmaSelectDialog';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import { countAlunos, countResponsaveis } from '../AlunoTurma/api-aluno-turma';

const useStyles = makeStyles({
    container: {
        paddingBottom: '20px',
    },
    title: {
        paddingTop: '20px'
    },
    novoRegistro: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    },
    loading: {
        marginTop: '20px',
    }
});


export default function VotacaoTurmaTable({ votacao }) {
    const classes = useStyles();
    const [votacaoTurmas, setVotacaoTurmas] = useState([]);
    const [turmas, setTurmas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openSearchTurmas, setOpenSearchTurmas] = useState(false);
    const [quantAlunos, setQuantAlunos] = useState(0);
    const [quantResponsaveis, setQuantResponsaveis] = useState(0);

    useEffect(() => {
        setQuantAlunos(0);
        setQuantResponsaveis(0);
        votacaoTurmas.forEach((ct) => {
            countAlunos(ct.turma.codigo, true).then((data) => {
                if (data) {
                    setQuantAlunos((prevState) => prevState + data);
                }
            })
            countResponsaveis(ct.turma.codigo, true).then((data) => {
                if (data) {
                    setQuantResponsaveis((prevState) => prevState + data);
                }
            })
        })





    }, [votacaoTurmas]);

    useEffect(() => {
        if (turmas.length > 0) {
            confirmAlert({
                title: 'Alerta',
                message: 'Deseja adicionar ' + turmas.length + ' turmas na votação?',
                buttons: [
                    {
                        label: 'Sim',
                        onClick: () => {
                            turmas.forEach((t) => {
                                const newVotacaoTurma = {
                                    votacao: votacao,
                                    turma: t
                                }
                                save(newVotacaoTurma).then((data) => {
                                    if (data) {
                                        if (data.error) {
                                            toast.error(`Turma ${t.descricao} já cadastrada.`, {
                                                position: toast.POSITION.BOTTOM_LEFT
                                            });
                                        } else {
                                            setVotacaoTurmas(prevState => ([
                                                ...prevState,
                                                data
                                            ]));
                                        }

                                    }

                                });
                            });
                            setTurmas([])
                        }
                    },
                    {
                        label: 'Não',
                        onClick: () => { }
                    }
                ]
            });


        }
    }, [turmas, votacao]);

    useEffect(() => {
        setIsLoading(true);
        findByVotacao(votacao.codigo).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setVotacaoTurmas(data);
                setIsLoading(false);
            }
        });
    }, [votacao.codigo]);


    const handleDelete = (index) => {
        if (!votacao.enviado) {
            confirmAlert({
                title: 'Alerta',
                message: 'Deseja deletar esta turma do comunicado?',
                buttons: [
                    {
                        label: 'Sim',
                        onClick: () => {
                            remove(votacaoTurmas[index].codigo).then((data) => {
                                if (data && data.error) {
                                    console.log(data.error);
                                } else {
                                    setVotacaoTurmas(
                                        votacaoTurmas.filter((ct) => ct.codigo !== votacaoTurmas[index].codigo)
                                    );

                                }
                            });
                        }
                    },
                    {
                        label: 'Não',
                        onClick: () => { }
                    }
                ]
            });
        }
    };

    return (
        <Container className={classes.container} >
            <TurmaSelectDialog
                openSearchTurmas={openSearchTurmas}
                setOpenSearchTurmas={setOpenSearchTurmas}
                setTurmasSelected={setTurmas}
            />
            <Typography className={classes.title} variant="h6">
                Comunicado Turma
            </Typography>
            {isLoading ?
                <div className={classes.loading}>
                    <CircularProgress color="primary" /> Carregando...
                </div> :
                <>
                    <Button
                        onClick={() => {
                            setTurmas([]);
                            setOpenSearchTurmas(true);
                        }}
                        variant="contained"
                        className={classes.novoRegistro}
                        disabled={votacao.enviado}
                        color="primary">
                        Adicionar Turma
                    </Button>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Curso</TableCell>
                                <TableCell>Turma</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {votacaoTurmas.map((ct, index) => (
                                <TableRow key={ct.codigo}>
                                    <TableCell>{ct.turma.curso.abreviacao}</TableCell>
                                    <TableCell>{ct.turma.descricao}</TableCell>
                                    <TableCell>
                                        <DeleteIcon
                                            fontSize='small'
                                            className={classes.cursorLink}
                                            onClick={() => { handleDelete(index) }}
                                            color="secondary"
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Typography variant="h6">
                        Quant. Alunos: {quantAlunos}
                    </Typography>
                    <Typography variant="h6">
                        Quant. Responsáveis: {quantResponsaveis}
                    </Typography>
                    <Typography variant="h6">
                        Total: {quantAlunos + quantResponsaveis}
                    </Typography>
                </>
            }

        </Container>
    );
}