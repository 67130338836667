import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '../UI/Button';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Switch from '../UI/Switch';
import { FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
    },
    submit: {
        margin: theme.spacing(3, 0, 3),
        textTransform: 'none',
    },
    buscarBtn: {
        margin: theme.spacing(3, 3, 3),
        textTransform: 'none',
    },
    closeIcon: {
        marginTop: '20px',
        marginBottom: '20px',
        cursor: 'pointer'
    },
    menuIcon: {
        marginTop: '15px',
        marginBottom: '20px',
        cursor: 'pointer'
    },
    divAlunoFoto: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    gridAbaMenu: {
        display: 'flex',
        justifyContent: 'space-around'
    }
}));

const InstituicaoForm = ({
    instituicao,
    setInstituicao,
    clickSubmit
}) => {
    const classes = useStyles();


    const handleChange = (event) => {
        setInstituicao({
            ...instituicao,
            [event.target.name]: event.target.value
        })
    }


    const handleSwitch = (name, value) => {
        setInstituicao({
            ...instituicao,
            [name]: value,
        });
    };

    return (
        <>
            <Container className={classes.paper} component={Paper} >
                <Grid container justifyContent="space-between">
                    <Grid item xs={3}>
                        <CloseIcon
                            onClick={() => setInstituicao(null)}
                            className={classes.closeIcon}
                            fontSize='small'
                        />
                    </Grid>


                </Grid>

                <Typography variant="h6">
                    Cadastro Instituição
                </Typography>
                <Grid item >
                    <form onSubmit={clickSubmit}>
                        <TextField
                            variant="outlined"
                            size="small"
                            margin="normal"
                            label="Descrição"
                            name="descricao"
                            autoFocus
                            onChange={handleChange}
                            inputProps={{ maxLength: 80 }}
                            value={instituicao && instituicao.descricao ? instituicao.descricao : ''}
                            fullWidth
                        />
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            inputProps={{ maxLength: 20 }}
                            label="CPF/CNPJ"
                            name="cpfCnpj"
                            onChange={handleChange}
                            value={instituicao && instituicao.cpfCnpj ? instituicao.cpfCnpj : ''}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            inputProps={{ maxLength: 200 }}
                            label="Endereço"
                            name="endereco"
                            onChange={handleChange}
                            value={instituicao && instituicao.endereco ? instituicao.endereco : ''}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            inputProps={{ maxLength: 100 }}
                            label="Bairro"
                            name="bairro"
                            onChange={handleChange}
                            value={instituicao && instituicao.bairro ? instituicao.bairro : ''}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            inputProps={{ maxLength: 100 }}
                            label="Cidade"
                            name="cidade"
                            onChange={handleChange}
                            value={instituicao && instituicao.cidade ? instituicao.cidade : ''}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            inputProps={{ maxLength: 5 }}
                            label="Estado"
                            name="estado"
                            onChange={handleChange}
                            value={instituicao && instituicao.estado ? instituicao.estado : ''}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            inputProps={{ maxLength: 15 }}
                            label="Telefone Contato"
                            name="telefoneContato"
                            onChange={handleChange}
                            value={instituicao && instituicao.telefoneContato ? instituicao.telefoneContato : ''}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            inputProps={{ maxLength: 10 }}
                            label="Código Banco"
                            name="codigoBanco"
                            onChange={handleChange}
                            value={instituicao && instituicao.codigoBanco ? instituicao.codigoBanco : ''}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            inputProps={{ maxLength: 2 }}
                            label="Dígito Código Banco"
                            name="digitoCodigoBanco"
                            onChange={handleChange}
                            value={instituicao && instituicao.digitoCodigoBanco ? instituicao.digitoCodigoBanco : ''}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            inputProps={{ maxLength: 10 }}
                            label="Agência"
                            name="agencia"
                            onChange={handleChange}
                            value={instituicao && instituicao.agencia ? instituicao.agencia : ''}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            inputProps={{ maxLength: 20 }}
                            label="Código Cedente"
                            name="codigoCedente"
                            onChange={handleChange}
                            value={instituicao && instituicao.codigoCedente ? instituicao.codigoCedente : ''}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            inputProps={{ maxLength: 2 }}
                            label="Dígito Código Cedente"
                            name="digitoCodigoCedente"
                            onChange={handleChange}
                            value={instituicao && instituicao.digitoCodigoCedente ? instituicao.digitoCodigoCedente : ''}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            inputProps={{ maxLength: 100 }}
                            label="Hostname"
                            name="hostname"
                            onChange={handleChange}
                            value={instituicao && instituicao.hostname ? instituicao.hostname : ''}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            inputProps={{ maxLength: 200 }}
                            label="Url Logo"
                            name="urlLogo"
                            onChange={handleChange}
                            value={instituicao && instituicao.urlLogo ? instituicao.urlLogo : ''}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            inputProps={{ maxLength: 200 }}
                            label="Url Logo App"
                            name="urlLogoApp"
                            onChange={handleChange}
                            value={instituicao && instituicao.urlLogoApp ? instituicao.urlLogoApp : ''}
                        />
                        <FormControlLabel
                            style={{ display: 'flex' }}
                            control={
                                <Switch
                                    handleSwitch={handleSwitch}
                                    value={instituicao && instituicao.livroHabilitado ? instituicao.livroHabilitado : ''}
                                    name="livroHabilitado"
                                />
                            }
                            label="Livro Habilitado"
                        />
                        <FormControlLabel
                            style={{ display: 'flex' }}
                            control={
                                <Switch
                                    handleSwitch={handleSwitch}
                                    value={instituicao && instituicao.avaliacaoHabilitado ? instituicao.avaliacaoHabilitado : ''}
                                    name="avaliacaoHabilitado"
                                />
                            }
                            label="Avaliação Habilitado"
                        />
                        <FormControlLabel
                            style={{ display: 'flex' }}
                            control={
                                <Switch
                                    handleSwitch={handleSwitch}
                                    value={instituicao && instituicao.mensagemHabilitado ? instituicao.mensagemHabilitado : ''}
                                    name="mensagemHabilitado"
                                />
                            }
                            label="Mensagem Habilitado"
                        />
                        <FormControlLabel
                            style={{ display: 'flex' }}
                            control={
                                <Switch
                                    handleSwitch={handleSwitch}
                                    value={instituicao && instituicao.muralHabilitado ? instituicao.muralHabilitado : ''}
                                    name="muralHabilitado"
                                />
                            }
                            label="Mural Habilitado"
                        />
                        <FormControlLabel
                            style={{ display: 'flex' }}
                            control={
                                <Switch
                                    handleSwitch={handleSwitch}
                                    value={instituicao && instituicao.smsLembreteHabilitado ? instituicao.smsLembreteHabilitado : ''}
                                    name="smsLembreteHabilitado"
                                />
                            }
                            label="SMS Lembrete Habilitado"
                        />
                        <FormControlLabel
                            style={{ display: 'flex' }}
                            control={
                                <Switch
                                    handleSwitch={handleSwitch}
                                    value={instituicao && instituicao.smsPendenciaHabilitado ? instituicao.smsPendenciaHabilitado : ''}
                                    name="smsPendenciaHabilitado"
                                />
                            }
                            label="SMS Pendência Habilitado"
                        />
                        <FormControlLabel
                            style={{ display: 'flex' }}
                            control={
                                <Switch
                                    handleSwitch={handleSwitch}
                                    value={instituicao && instituicao.ativo ? instituicao.ativo : ''}
                                    name="ativo"
                                />
                            }
                            label="Ativo"
                        />
                        <FormControlLabel
                            style={{ display: 'flex' }}
                            control={
                                <Switch
                                    handleSwitch={handleSwitch}
                                    value={instituicao && instituicao.comunicadoAprovacao ? instituicao.comunicadoAprovacao : ''}
                                    name="comunicadoAprovacao"
                                />
                            }
                            label="Comunicado Aprovação"
                        />
                        <FormControlLabel
                            style={{ display: 'flex' }}
                            control={
                                <Switch
                                    handleSwitch={handleSwitch}
                                    value={instituicao && instituicao.bloqueiaEntradaCatracaInadimplente ? instituicao.bloqueiaEntradaCatracaInadimplente : ''}
                                    name="bloqueiaEntradaCatracaInadimplente"
                                />
                            }
                            label="Bloqueio Entrada Catraca Inadimplente"
                        />
                        <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            inputProps={{ maxLength: 3 }}
                            label="Quant. Dias Antes Hoje Busca Inadimplente"
                            name="quantDiasAntesHojeBuscaInadimplente"
                            onChange={handleChange}
                            value={instituicao && instituicao.quantDiasAntesHojeBuscaInadimplente ? instituicao.quantDiasAntesHojeBuscaInadimplente : ''}
                        />
                        <FormControlLabel
                            style={{ display: 'flex' }}
                            control={
                                <Switch
                                    handleSwitch={handleSwitch}
                                    value={instituicao && instituicao.servicoPresenca ? instituicao.servicoPresenca : ''}
                                    name="servicoPresenca"
                                />
                            }
                            label="Serviço Presença"
                        />
                        <FormControlLabel
                            style={{ display: 'flex' }}
                            control={
                                <Switch
                                    handleSwitch={handleSwitch}
                                    value={instituicao && instituicao.servicoPresenteFalta ? instituicao.servicoPresenteFalta : ''}
                                    name="servicoPresenteFalta"
                                />
                            }
                            label="Serviço Presente Falta"
                        />



                        <Grid containe style={{ margin: '20px' }} direction='row' spacing={3}>
                            <Grid item>
                                <Button
                                    type="submit"
                                >
                                    Salvar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>

                </Grid>
            </Container>
        </ >
    );
}

export default InstituicaoForm;