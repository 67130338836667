import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../util/FetchUtil';

const controller = 'mural';

const page = (pageNumber) => {
  const url = SERVER_URL + controller + '?page=' + pageNumber;
  return fetchGetAuth(url);
}

export const sendMuralPushNotification = (codMural) => {
  const url = SERVER_URL + controller + '/send-push-notification/' + codMural;
  return fetchGetAuth(url);
}

const save = (mural) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, mural);
}

const deleteById = (codMural) => {
  const url = SERVER_URL + controller + "/" + codMural;
  return fetchRemoveAuthReturn(url);
}

export {
  page,
  save,
  deleteById
}