import { ADMIN, COORDENADOR, SECRETARIA, PROFESSOR, ALUNO, RESPONSAVEL, MASTER } from '../../../components/Usuario/Perfil';

const LateralMenuList = [
    {
        descricao: 'Acompanhamento',
        access: [ADMIN, COORDENADOR, PROFESSOR],
        link: '/acompanhamento'
    },
    {
        descricao: 'Aluno',
        access: [ADMIN, COORDENADOR, SECRETARIA],
        link: '/alunos'
    },
    {
        descricao: 'Aluno Foto',
        access: [ADMIN, COORDENADOR],
        link: '/aluno-foto'
    },
    {
        descricao: 'Aluno Frequência',
        access: [ADMIN, COORDENADOR, PROFESSOR],
        link: '/ausencia-compensada'
    },
    {
        descricao: 'Aluno Turma',
        access: [ADMIN, COORDENADOR],
        link: '/aluno-turma'
    },
    {
        descricao: 'Atividade',
        access: [ADMIN, COORDENADOR, PROFESSOR],
        link: '/atividade'
    },

    {
        descricao: 'Atividade',
        access: [ALUNO, RESPONSAVEL],
        link: '/aluno/atividade-aluno'
    },
    {
        descricao: 'Avaliação',
        access: [ADMIN, COORDENADOR, PROFESSOR, ALUNO],
        link: '/avaliacao'
    },
    {
        descricao: 'Bimestre',
        access: [ADMIN, COORDENADOR],
        link: '/bimestre'
    },
    {
        descricao: 'Cronograma',
        access: [ADMIN, COORDENADOR],
        link: '/cronograma'
    },
    {
        descricao: 'Cronograma',
        access: [PROFESSOR],
        link: '/cronograma-professor'
    },
    {
        descricao: 'Cronograma Aula',
        access: [ADMIN, COORDENADOR],
        link: '/cronograma-aula'
    },
    {
        descricao: 'Cronograma Quant. Aula Bimestre',
        access: [ADMIN, COORDENADOR],
        link: '/cronograma-quant-aula-bimestre'
    },
    {
        descricao: 'Cronograma Quant. Aula Dia',
        access: [ADMIN, COORDENADOR],
        link: '/cronograma-quant-aula-dia'
    },
    {
        descricao: 'Comunicado',
        access: [ADMIN, COORDENADOR, PROFESSOR],
        link: '/comunicado'
    },
    {
        descricao: 'Comunicado',
        access: [ALUNO, RESPONSAVEL],
        link: '/comunicado-aluno'
    },
    {
        descricao: 'Curso',
        access: [ADMIN, COORDENADOR],
        link: '/curso'
    },
    {
        descricao: 'Disciplina',
        access: [ADMIN, COORDENADOR],
        link: '/disciplina'
    },
    {
        descricao: 'Disciplina Por Turma',
        access: [ADMIN, COORDENADOR],
        link: '/disciplina-turma'
    },
    {
        descricao: 'Evento',
        access: [ADMIN, COORDENADOR, PROFESSOR],
        link: '/evento'
    },
    {
        descricao: 'Evento',
        access: [ALUNO, RESPONSAVEL],
        link: '/evento-aluno'
    },
    {
        descricao: 'Financeiro',
        access: [ADMIN, SECRETARIA],
        link: '/financeiro-instituicao'
    },
    {
        descricao: 'Financeiro',
        access: [RESPONSAVEL],
        link: '/financeiro-responsavel'
    },
    {
        descricao: 'Instituição',
        access: [MASTER],
        link: '/instituicao'
    },
    {
        descricao: 'Integral',
        access: [ADMIN, COORDENADOR, PROFESSOR, ALUNO, RESPONSAVEL],
        link: '/integral'
    },
    {
        descricao: 'Integral Professor',
        access: [ADMIN, COORDENADOR],
        link: '/integral-professor'
    },
    {
        descricao: 'Livro',
        access: [ADMIN, COORDENADOR],
        link: '/livro-instituicao'
    },
    {
        descricao: 'Livro',
        access: [PROFESSOR],
        link: '/livro-professor'
    },
    {
        descricao: 'Livro',
        access: [ALUNO],
        link: '/livros'
    },
    {
        descricao: 'Livro por Disciplina/Turma',
        access: [ADMIN, COORDENADOR],
        link: '/livro-disciplina-turma'
    },
    {
        descricao: 'Local',
        access: [ADMIN, COORDENADOR],
        link: '/local'
    },
    {
        descricao: 'Mensagem',
        access: [ADMIN, COORDENADOR, PROFESSOR, SECRETARIA, ALUNO, RESPONSAVEL],
        link: '/mensagem'
    },
    {
        descricao: 'Monitor de Acesso',
        access: [ADMIN, COORDENADOR, PROFESSOR],
        link: '/monitor-acesso-menu'
    },
    {
        descricao: 'Monitor de Acesso',
        access: [ALUNO, RESPONSAVEL],
        link: '/monitor-acesso-usuario'
    },
    {
        descricao: 'Mural',
        access: [ADMIN, COORDENADOR, PROFESSOR],
        link: '/mural'
    },
    {
        descricao: 'Nota Bimestre',
        access: [ADMIN, COORDENADOR, PROFESSOR],
        link: '/nota-bimestre'
    },
    {
        descricao: 'Nota Bimestre',
        access: [ALUNO, RESPONSAVEL],
        link: '/aluno/nota-bimestre-aluno'
    },
    {
        descricao: 'Observação Aluno',
        access: [ADMIN, COORDENADOR, PROFESSOR],
        link: '/observacao-aluno'
    },
    {
        descricao: 'Observação Aluno - V2',
        access: [ADMIN, COORDENADOR, PROFESSOR],
        link: '/observacao-aluno-menu'
    },
    {
        descricao: 'Observação Aluno',
        access: [ALUNO],
        link: '/observacao-user'
    },
    {
        descricao: 'Observação Turma',
        access: [ADMIN, COORDENADOR, PROFESSOR],
        link: '/observacao-turma'
    },
    {
        descricao: 'Planejamento',
        access: [ADMIN, COORDENADOR, PROFESSOR],
        link: '/planejamento'
    },
    {
        descricao: 'Presença',
        access: [ADMIN, COORDENADOR, PROFESSOR],
        link: '/presenca-menu'
    },
    {
        descricao: 'Presença',
        access: [ALUNO, RESPONSAVEL],
        link: '/aluno/presenca-aluno'
    },
    {
        descricao: 'Professor por Disciplina/Turma',
        access: [ADMIN, COORDENADOR],
        link: '/professor-disciplina-turma'
    },
    {
        descricao: 'Relatório',
        access: [ADMIN, COORDENADOR, PROFESSOR, SECRETARIA],
        link: '/relatorio'
    },
    {
        descricao: 'Relatório',
        access: [ALUNO, RESPONSAVEL],
        link: '/relatorio-aluno'
    },
    {
        descricao: 'Série',
        access: [ADMIN, COORDENADOR],
        link: '/serie'
    },
    {
        descricao: 'Turma',
        access: [ADMIN, COORDENADOR],
        link: '/turma'
    },
    {
        descricao: 'Usuário',
        access: [ADMIN, COORDENADOR, SECRETARIA],
        link: '/usuarios'
    },
    {
        descricao: 'Votação',
        access: [ADMIN, COORDENADOR, PROFESSOR],
        link: '/votacao'
    },
    {
        descricao: 'Votação',
        access: [RESPONSAVEL, ALUNO],
        link: '/votacao-usuario'
    },

]

export default LateralMenuList;