import React, { useEffect, useState } from 'react';
import Container from '../UI/Container';
import CloseIcon from '@material-ui/icons/Close';
import { FormControl, FormControlLabel, Grid, InputLabel, makeStyles, MenuItem, Select, Switch, Typography } from '@material-ui/core';
import TextField from '../UI/TextField';
import { toast } from 'react-toastify';
import { save, remove, consultarTransacao } from './api-lancamento';
import Button from '../UI/Button';
import TextFieldReadOnly from '../UI/TextFieldReadOnly';
import TextFieldDate from '../UI/TextFieldDate';
import { SECRETARIA, PIX, BOLETO } from './FormaPago';
import { findByCurrentUser } from './HistoricoLancamento/api-historico-lancamento';
import { confirmAlert } from 'react-confirm-alert';
import { errorMessage } from '../../util/ConfirmAlertUtil';


const useStyles = makeStyles({
    closeIcon: {
        marginTop: '20px',
        marginBottom: '20px',
        cursor: 'pointer'
    },
});

const LancamentoForm = ({ aluno, lancamento, setLancamento, addLancamentos, updateLancamentos, removeLancamentos }) => {
    const [historicosLancamentos, setHistoricosLancamentos] = useState([]);
    const classes = useStyles();
    const [isLoadingAction, setIsLoadingAction] = useState(false);

    useEffect(() => {
        findByCurrentUser().then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setHistoricosLancamentos(data);
            }
        })
    }, []);


    const clickSubmit = (event) => {
        event.preventDefault();
        const eNovo = lancamento.eNovo;
        setIsLoadingAction(true);
        save(lancamento).then((data) => {
            if (data.error) {
                console.log(data);

            } else {
                toast.success("Salvo com sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                if (eNovo) {
                    addLancamentos(data)
                } else {
                    updateLancamentos(data)
                }
                setLancamento(data);
            }
            setIsLoadingAction(false);
        })
    }

    const handleChange = (event) => {
        setLancamento({
            ...lancamento,
            [event.target.name]: event.target.value
        })
    }

    const handleChangeHistoricoLancamento = (event) => {
        setLancamento({
            ...lancamento,
            historicoLancamento: historicosLancamentos.find((hl) => hl.codigo === event.target.value)
        })
    }

    const handleDateChange = (date, name) => {
        setLancamento({
            ...lancamento,
            [name]: date
        })
    }

    const handleSwitchChange = name => event => {
        setLancamento({ ...lancamento, [name]: event.target.checked });
    };

    const handleRemove = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja remover este lançamento?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setIsLoadingAction(true);
                        remove(lancamento.codigo).then((data) => {
                            if (data) {
                                removeLancamentos(lancamento.codigo)
                                setLancamento(null);
                            } else {
                                console.log(data.error);
                            }
                            setIsLoadingAction(false);
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleConsultarTransacao = () => {
        setIsLoadingAction(true);
        consultarTransacao(lancamento.codigo).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    updateLancamentos(data);
                    setLancamento(data);
                }
            }
            setIsLoadingAction(false);
        });
    };

    return (
        <Container>
            <CloseIcon
                onClick={() => setLancamento(null)}
                className={classes.closeIcon}
                fontSize='small'
            />
            <Typography variant="h6">
                Cadastro Lançamento
            </Typography>
            <form onSubmit={clickSubmit}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2}
                        lg={2}
                        xl={2}
                    >
                        <TextField
                            label="Ano Letivo"
                            name="anoLetivo"
                            required
                            autoFocus
                            onChange={handleChange}
                            value={lancamento && lancamento.anoLetivo ? lancamento.anoLetivo : ''}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2}
                        lg={2}
                        xl={2}
                    >
                        <TextField
                            label="Código"
                            name="codDoc"
                            required
                            onChange={handleChange}
                            value={lancamento && lancamento.codDoc ? lancamento.codDoc : ''}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <TextFieldReadOnly
                            fullWidth={true}
                            value={aluno && aluno.nome}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}>
                        <TextFieldDate
                            label="Data Vencimento"
                            required
                            onChange={event => handleDateChange(event, 'dataVencimento')}
                            value={lancamento && lancamento.dataVencimento ? lancamento.dataVencimento : null}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}>
                        <TextField
                            label="Valor"
                            type='number'
                            name="valor"
                            required
                            onChange={handleChange}
                            value={lancamento && lancamento.valor ? lancamento.valor : ''}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                    >
                        <TextFieldDate
                            label="Data Desconto"
                            onChange={event => handleDateChange(event, 'dataDesconto')}
                            value={lancamento && lancamento.dataDesconto ? lancamento.dataDesconto : null}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                    >
                        <TextField
                            label="Valor Desconto"
                            type='number'
                            name="valorDesconto"
                            onChange={handleChange}
                            value={lancamento && lancamento.valorDesconto ? lancamento.valorDesconto : ''}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                    >
                        <TextFieldDate
                            label="Data Pagamento"
                            onChange={event => handleDateChange(event, 'dataPagamento')}
                            value={lancamento && lancamento.dataPagamento ? lancamento.dataPagamento : null}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                    >
                        <TextField
                            label="Valor Pago"
                            type='number'
                            name="valorPago"
                            onChange={handleChange}
                            value={lancamento && lancamento.valorPago ? lancamento.valorPago : ''}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <TextField
                            label="Código de Barras"
                            type='number'
                            name="codigoBarras"
                            fullWidth
                            onChange={handleChange}
                            value={lancamento && lancamento.codigoBarras ? lancamento.codigoBarras : ''}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                    >
                        <FormControl fullWidth>
                            <InputLabel >Forma Pago</InputLabel>
                            <Select
                                name="formaPago"
                                value={lancamento && lancamento.formaPago ? lancamento.formaPago : ''}
                                onChange={handleChange}
                            >
                                <MenuItem value={SECRETARIA}>{SECRETARIA}</MenuItem>
                                <MenuItem value={PIX}>{PIX}</MenuItem>
                                <MenuItem value={BOLETO}>{BOLETO}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                    >
                        <FormControl
                            fullWidth
                            required
                        >
                            <InputLabel>Histórico Lançamento</InputLabel>
                            <Select
                                name="historicoLancamento"
                                value={lancamento && lancamento.historicoLancamento ? lancamento.historicoLancamento.codigo : ''}
                                onChange={handleChangeHistoricoLancamento}
                            >
                                {historicosLancamentos.map((hl) => (
                                    <MenuItem key={hl.codigo} value={hl.codigo}>{hl.descricao}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <FormControlLabel control={<Switch
                            id="quitado-switch"
                            checked={lancamento && lancamento.quitado ? true : false}
                            onChange={handleSwitchChange('quitado')}
                            value={lancamento && lancamento.quitado ? true : false}
                        />} label="Quitado" />


                    </Grid>
                    {lancamento.pixTransacao &&
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                        >
                            <Button
                                disabled={isLoadingAction}
                                onClick={handleConsultarTransacao}
                            >
                                Consultar Transação
                            </Button>
                        </Grid>
                    }
                    <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                    >
                        <Button
                            type="submit"
                            disabled={isLoadingAction}
                        >
                            Salvar
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                    >
                        <Button
                            disabled={isLoadingAction}
                            color="secondary"
                            onClick={handleRemove}
                        >
                            Remover
                        </Button>
                    </Grid>


                </Grid>

            </form>
        </Container>
    );
}

export default LancamentoForm;