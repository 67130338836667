import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchPostAuth
} from '../../util/FetchUtil';

const controller = 'votacao';

export const findVotacaoPage = (pageNumber) => {
  const url = SERVER_URL + controller + '/page-query?page=' + pageNumber;
  return fetchGetAuth(url);
}

export const sendVotacao = (codVotacao) => {
  const url = SERVER_URL + controller + '/send/' + codVotacao;
  return fetchGetAuth(url);
}

export const saveVotacao = (votacao) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, votacao);
}