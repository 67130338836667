import DateFnsUtils from '@date-io/date-fns';
import { FormControl, FormControlLabel, Grid, InputLabel, makeStyles, MenuItem, Select, Switch, TextField, Typography } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import React, { useState } from 'react';
import SendIcon from '@material-ui/icons/Send';
import Button from '../UI/Button';
import { toast } from 'react-toastify';
import SimpleDialogMessage from '../../util/SimpleDialogMessage/SimpleDialogMessage';
import { open } from '../../util/SimpleDialogMessage/actions/actions';
import { useDispatch } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { saveVotacao, sendVotacao } from './api-votacao';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import CloseIcon from '@material-ui/icons/Close';
import VotacaoTurmaTable from '../VotacaoTurma/VotacaoTurmaTable';
import VotacaoAlunoTable from '../VotacaoAluno/VotacaoAlunoTable';
import VotacaoOpcaoContainer from '../VotacaoOpcao/VotacaoOpcaoContainer';

const useStyles = makeStyles({
    send: {
        backgroundColor: '#4caf50'
    },
    closeIcon: {
        marginTop: '20px',
        marginBottom: '10px',
        cursor: 'pointer'
    },

});


const VotacaoForm = ({ votacao, setVotacao, setPageVotacao }) => {
    const enviarPor = ['Aluno', 'Turma'];
    const enviarPara = ['Ambos', 'Alunos', 'Responsáveis'];
    const [isPerforming, setIsPerforming] = useState(false);
    const [message, setMessage] = useState({});
    const dispatch = useDispatch();
    const classes = useStyles();
    const votacaoCadastroKey = 'votacao-cadastro';
    const votacaoOpcaoKey = 'votacao-opcao';
    const enviarPorKey = 'enviar-por';
    const [menu, setMenu] = useState(votacaoCadastroKey);

    const handleEnviarPorChange = (event) => {
        const ep = enviarPor.find((ep) => {
            return ep === event.target.value
        });
        setVotacao({
            ...votacao,
            enviarPor: ep
        });
    }

    const handleEnviarParaChange = (event) => {
        const ep = enviarPara.find((ep) => {
            return ep === event.target.value
        });
        setVotacao({
            ...votacao,
            enviarPara: ep
        });
    }

    const handleChange = (event) => {
        setVotacao({
            ...votacao,
            [event.target.name]: event.target.value
        })
    }

    const handleSwitchvisualizarResultadoAtualChange = (event) => {
        setVotacao((prevState) => ({
            ...prevState,
            visualizarResultadoAtual: event.target.checked,
            visulizarResultadoAPartirDe: !event.target.checked
        }));
    };

    const handleSwitchvisulizarResultadoAPartirDeChange = (event) => {
        setVotacao((prevState) => ({
            ...prevState,
            visulizarResultadoAPartirDe: event.target.checked,
            visualizarResultadoAtual: !event.target.checked
        }));
    };

    const handleDataHoraVisualizarResultadoAPartirDeChange = (dataHoraVisualizar) => {

        setVotacao({
            ...votacao,
            dataHoraVisualizarResultadoAPartirDe: dataHoraVisualizar
        })
    }

    const handleDataHoraDisponivelAteChange = (dataHoraDisponivelAte) => {
        setVotacao({
            ...votacao,
            dataHoraDisponivelAte: dataHoraDisponivelAte
        })
    }

    const updatePageVotacao = (data) => {
        setPageVotacao((prevState) => ({
            ...prevState,
            content: prevState.content.map((ps) => {
                if (ps.codigo === data.codigo) {
                    return data;
                } else {
                    return ps;
                }
            })
        }));
    }

    const handleSend = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja enviar esta votação? Após confirmado esta operação não poderá ser desfeita.',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setIsPerforming(true);
                        sendVotacao(votacao.codigo).then(data => {
                            if (data) {
                                if (data.error) {
                                    if (data.message === 'No turma registered') {
                                        setMessage({
                                            title: 'Votação não foi enviado',
                                            description: 'Votação não possui turmas cadastradas para o envio.'
                                        });
                                        dispatch(open());

                                    } else if (data.message === 'No aluno registered') {
                                        setMessage({
                                            title: 'Votação não foi enviado',
                                            description: 'Votação não possui alunos cadastrados para o envio.'
                                        });
                                        dispatch(open());

                                    }
                                } else {
                                    toast.success("Votação Enviada com Sucesso.", {
                                        position: toast.POSITION.BOTTOM_LEFT
                                    });
                                    updatePageVotacao(data);
                                    setVotacao(data);
                                }
                            }
                            setIsPerforming(false);
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleSave = () => {
        setIsPerforming(true);
        const eNovo = votacao.eNovo;
        if (!votacao.enviarPara) {
            toast.error("Selecione para quem será enviado a votação.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }
        saveVotacao(votacao).then(data => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    toast.success("Salvo com sucesso.", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                    if (eNovo) {
                        setPageVotacao((prevState) => ({
                            ...prevState,
                            content: [data, ...prevState.content]
                        }));
                    } else {
                        updatePageVotacao(data)
                    }
                    setVotacao(data);
                }
            }
            setIsPerforming(false);
        })
    }

    return (
        <Grid container spacing={3}>
            <SimpleDialogMessage title={message.title} description={message.description} />
            <Grid item xs={12}>
                <CloseIcon
                    onClick={() => setVotacao(null)}
                    className={classes.closeIcon}
                    fontSize='small'
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container style={{
                    border: '1px solid black',
                    borderRadius: '30px',
                    padding: '20px',
                    marginBottom: '20px',
                    marginTop: '5px',
                }}
                    direction='row'
                    spacing={3}
                    justifyContent='space-around'
                >

                    <Grid item xs={12}><Typography variant='h5' style={{ textAlign: 'center' }}>Menu</Typography></Grid>
                    <Grid item><Button onClick={() => setMenu(votacaoCadastroKey)}>Votação Cadastro</Button></Grid>
                    {votacao.codigo && <>
                        <Grid item><Button onClick={() => setMenu(votacaoOpcaoKey)}>Votação Opção</Button></Grid>
                        <Grid item><Button onClick={() => setMenu(enviarPorKey)}>Enviar Por</Button></Grid>
                    </>}
                </Grid>
            </Grid>
            {menu === votacaoCadastroKey &&
                <>
                    <Grid item xs={4}>
                        <FormControl fullWidth >
                            <InputLabel id="enviar-por-label">Enviar Por:</InputLabel>
                            <Select
                                disabled={votacao.enviado}
                                labelId="enviar-por-label"
                                id="enviar-por-label"
                                value={
                                    votacao.enviarPor ?
                                        votacao.enviarPor :
                                        ''
                                }
                                onChange={handleEnviarPorChange}
                            >
                                {enviarPor.map((ep, index) => (
                                    <MenuItem key={index} value={ep}>
                                        {ep}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth >
                            <InputLabel id="perfil-label">Enviar Para:</InputLabel>
                            <Select
                                disabled={votacao.enviado}
                                labelId="enviar-para-label"
                                id="enviar-para-select"
                                value={
                                    votacao.enviarPara ?
                                        votacao.enviarPara :
                                        ''
                                }
                                onChange={handleEnviarParaChange}
                            >
                                {enviarPara.map((ep, index) => (
                                    <MenuItem key={index} value={ep}>
                                        {ep}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            size='small'
                            label="Quantidade de Resposta por Usuário"
                            name="quantUsuarioOpcao"
                            type='number'
                            onChange={handleChange}
                            value={votacao && votacao.quantUsuarioOpcao ? votacao.quantUsuarioOpcao : ''}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={votacao && votacao.visualizarResultadoAtual ? true : false}
                                    onChange={handleSwitchvisualizarResultadoAtualChange}
                                    value={votacao && votacao.visualizarResultadoAtual ? true : false}
                                />
                            }
                            label="Visualizar Resultado Atual"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={votacao && votacao.visulizarResultadoAPartirDe ? true : false}
                                    onChange={handleSwitchvisulizarResultadoAPartirDeChange}
                                    value={votacao && votacao.visulizarResultadoAPartirDe ? true : false}
                                />
                            }
                            label="Visualizar Resultado A partir de"
                        />

                        {votacao && votacao.visulizarResultadoAPartirDe ?
                            <div>
                                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                                    <DateTimePicker
                                        size='small'
                                        ampm={false}
                                        minutesStep={60}
                                        required
                                        variant="outlined"
                                        margin="normal"
                                        label="Data Hora Agendamento"
                                        value={votacao && votacao.dataHoraVisualizarResultadoAPartirDe ? votacao.dataHoraVisualizarResultadoAPartirDe : null}
                                        onChange={handleDataHoraVisualizarResultadoAPartirDeChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            : ''}
                    </Grid>
                    <Grid item xs={4}>

                        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                            <DateTimePicker
                                size='small'
                                ampm={false}
                                minutesStep={60}
                                required
                                variant="outlined"
                                margin="normal"
                                label="Data Hora Disponível Até"
                                value={votacao && votacao.dataHoraDisponivelAte ? votacao.dataHoraDisponivelAte : null}
                                onChange={handleDataHoraDisponivelAteChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            size='small'
                            required
                            fullWidth
                            label="Título"
                            name="titulo"
                            onChange={handleChange}
                            inputProps={{ maxLength: 300 }}
                            value={votacao && votacao.titulo ? votacao.titulo : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            size='small'
                            required
                            fullWidth
                            label="Descrição"
                            name="descricao"
                            multiline
                            minRows={4}
                            onChange={handleChange}
                            inputProps={{ maxLength: 2000 }}
                            value={votacao && votacao.descricao ? votacao.descricao : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>

                        <Grid container justifyContent="space-between">
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={isPerforming}
                                onClick={handleSave}
                            >
                                Salvar
                            </Button>
                            {!votacao.eNovo &&
                                <Button
                                    disabled={isPerforming}
                                    onClick={handleSend}
                                    startIcon={<SendIcon />}
                                    variant="contained"
                                    className={classes.send}
                                >
                                    Enviar
                                </Button>
                            }


                        </Grid>
                    </Grid>
                </>
            }
            {menu === votacaoOpcaoKey &&
                <Grid item xs={12}>
                    {votacao && votacao.codigo ?
                        <VotacaoOpcaoContainer votacao={votacao} />
                        : ''}

                </Grid>
            }
            {menu === enviarPorKey &&
                <>
                    <Grid item xs={12}>
                        {votacao && votacao.codigo && votacao.enviarPor === 'Turma' ?
                            <VotacaoTurmaTable votacao={votacao} />
                            : ''}
                        {votacao && votacao.codigo && votacao.enviarPor === 'Aluno' ?
                            <VotacaoAlunoTable
                                votacao={votacao}
                            />
                            : ''}
                    </Grid>
                </>
            }




        </Grid>
    )
}

export default VotacaoForm;