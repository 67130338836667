import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import { openSearchTurmas, setTurmas } from '../../Turma/actions/actions';
import Button from '../../UI/Button';
import { save, findByMuralCodigo, deleteById } from './api-mural-turma';
import DeleteIcon from '@material-ui/icons/Delete';
import Loading from '../../UI/Loading';
import TurmaFindDialogRedux from "../../Turma/TurmaSelectDialogRedux";

const useStyles = makeStyles({
    cursorLink: {
        cursor: 'pointer'
    },
})

const MuralTurma = ({
    mural
}) => {
    const classes = useStyles();
    const turmas = useSelector(state => state.turmas);
    const [isAdding, setIsAdding] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAction, setIsLoadingAction] = useState(false);
    const dispatch = useDispatch();
    const [muralTurmas, setMuralTurmas] = useState([]);


    useEffect(() => {
        if (turmas.length > 0 && isAdding) {
            confirmAlert({
                title: 'Alerta',
                message: 'Deseja adicionar ' + turmas.length + ' turmas no mural?',
                buttons: [
                    {
                        label: 'Sim',
                        onClick: async () => {
                            setIsLoadingAction(true);
                            await turmas.forEach((t) => {
                                const newMuralTurma = {
                                    mural: mural,
                                    turma: t
                                }
                                save(newMuralTurma).then((data) => {
                                    if (data) {
                                        if (data.error) {
                                            errorMessage(data.message);
                                            // toast.error(`Turma ${t.descricao} já cadastrada.`, {
                                            //     position: toast.POSITION.BOTTOM_LEFT
                                            // });
                                        } else {
                                            setMuralTurmas(prevState => ([
                                                ...prevState,
                                                data
                                            ]));
                                        }

                                    }

                                });
                            });
                            setIsLoadingAction(false);
                        }
                    },
                    {
                        label: 'Não',
                        onClick: () => { }
                    }
                ]
            });

            dispatch(setTurmas([]));
            setIsAdding(false);
        }
    }, [turmas, dispatch, isAdding, mural]);

    useEffect(() => {
        setIsLoading(true);
        findByMuralCodigo(mural.codigo).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message)
                } else {
                    setMuralTurmas(data);
                }
            }
            setIsLoading(false);
        });
    }, [mural.codigo]);

    const handleDelete = (index) => {

        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar esta turma do mural?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setIsLoadingAction(true);
                        deleteById(muralTurmas[index].codigo).then((data) => {
                            if (data) {
                                if (data.error) {
                                    errorMessage(data.message);
                                } else {
                                    setMuralTurmas(
                                        muralTurmas.filter((mt) => mt.codigo !== muralTurmas[index].codigo)
                                    );
                                }
                            }
                            setIsLoadingAction(false);
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    return (
        <>
            <Typography variant="h6">
                Mural Turma
            </Typography>
            <TurmaFindDialogRedux />
            {isLoading ?
                <Loading description="Carregando Mural Turmas..." /> :
                <>
                    <Button
                        onClick={() => {
                            dispatch(setTurmas([]));
                            dispatch(openSearchTurmas());
                            setIsAdding(true);
                        }}
                        variant="contained"
                        disabled={isLoadingAction}
                        color="primary"
                    >
                        {isLoadingAction && <Loading />}
                        Adicionar Turma
                    </Button>
                    {muralTurmas.length > 0 ?
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Curso</TableCell>
                                    <TableCell>Turma</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {muralTurmas.map((mt, index) => (
                                    <TableRow key={mt.codigo}>
                                        <TableCell>{mt.turma.curso.abreviacao}</TableCell>
                                        <TableCell>{mt.turma.descricao}</TableCell>
                                        <TableCell>
                                            <DeleteIcon
                                                fontSize='small'
                                                className={classes.cursorLink}
                                                onClick={() => { handleDelete(index) }}
                                                color="secondary"
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table> :
                        <Typography variant="body1">
                            Nenhum Mural Turma Cadastrado.
                        </Typography>

                    }
                </>
            }
        </>
    )
}

export default MuralTurma;