import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchPostAuth,
  fetchPostAuthFile,
  fetchRemoveAuth,
  fetchRemoveAuthReturn
} from '../../util/FetchUtil';

const controller = 'votacao-opcao/';

export const findVotacaoOpcaoByVotacaoCodigo = (codVotacao) => {
  const url = SERVER_URL + controller + 'query-list?cod-votacao=' + codVotacao;
  return fetchGetAuth(url);
}

export const saveVotacaoOpcao = (votacaoOpcao) => {
  const url = SERVER_URL + controller + "save";
  return fetchPostAuth(url, votacaoOpcao);
}

export const removeVotacaoOpcao = (codVotacaoOpcao) => {
  const url = SERVER_URL + controller + codVotacaoOpcao;
  return fetchRemoveAuth(url);
}

export const uploadImagemVotacaoOpcao = (codigo, formData) => {
  const url = SERVER_URL + controller + 'upload-imagem/' + codigo;
  return fetchPostAuthFile(url, formData);
}

export const removeUploadedImagemVotacaoOpcao = (codigo) => {
  const url = SERVER_URL + controller + "remove-uploaded-imagem/" + codigo;
  return fetchRemoveAuthReturn(url);
}
