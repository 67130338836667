import React, { useEffect, useState } from 'react';
import { findInstituicaoAll, saveMasterInstituicao } from './api-instituicao';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import Container from '../UI/Container';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import InstituicaoTable from './InstituicaoTable';
import InstituicaoForm from './InstituicaoForm';
import { toast } from 'react-toastify';


const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

const InstituicaoContainer = () => {
    const classes = useStyles();
    const [pageInstituicoes, setPageInstituicoes] = useState({
        content: [],
        size: 0,
        number: 0,
        totalElements: 0
    });
    const [instituicao, setInstituicao] = useState(null);
    const size = 10
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchDataInstituicao(0);
        // eslint-disable-next-line
    }, []);

    const fetchDataInstituicao = (newPage) => {
        setIsLoading(true);
        findInstituicaoAll(newPage, size).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setPageInstituicoes(data);
                }
            }
            setIsLoading(false);
        })
    }

    const handleChangePage = (event, newPage) => {
        fetchDataInstituicao(newPage);
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        const eNovo = instituicao.eNovo;
        saveMasterInstituicao(instituicao).then(data => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    toast.success("Salvo com sucesso.", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                    if (eNovo) {
                        setPageInstituicoes((prevState) => ({
                            ...prevState,
                            content: [data, ...prevState.content]
                        }));
                    } else {
                        setPageInstituicoes((prevState) => ({
                            ...prevState,
                            content: prevState.content.map((ps) => {
                                if (ps.codigo === data.codigo) {
                                    return data;
                                } else {
                                    return ps;
                                }
                            })
                        }))
                    }
                    setInstituicao(data);
                }
            }

        })
    }


    return (
        <Container>

            <Grid container justifyContent="space-between">
                <Grid item xs={3}>
                    <Typography className={classes.title} variant="h6">
                        Instituição
                    </Typography>
                </Grid>
            </Grid>
            {instituicao ?
                <InstituicaoForm
                    instituicao={instituicao}
                    setInstituicao={setInstituicao}
                    clickSubmit={clickSubmit}
                />
                :
                <InstituicaoTable
                    pageInstituicoes={pageInstituicoes}
                    handleChangePage={handleChangePage}
                    setInstituicao={setInstituicao}
                    isLoading={isLoading}
                />
            }

        </Container>
    );
}

export default InstituicaoContainer;