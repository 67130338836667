import { Grid, TablePagination, Typography } from '@material-ui/core';
import React from 'react';
import Button from '../UI/Button';
import TablePaginationActions from '../../shared/components/Table/Actions';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';

const VotacaoUsuarioTable = ({ pageVotacaoUsuario, handleChangePage, setVotacaoUsuario }) => {
    return (
        <>
            <Grid container spacing={3}>

                {pageVotacaoUsuario.totalElements > 0 ?
                    <>
                        {pageVotacaoUsuario.content.map((votacaoUsuario) => (
                            <Grid item xs={12}
                                style={{
                                    border: '1px solid black',
                                    borderRadius: '20px'
                                }}
                            >
                                <Grid container direction='row' justifyContent='center' alignItems='center' alignContent='center' spacing={3}>
                                    <Grid item xs={4}>
                                        {/* <Typography variant='h6'>Título</Typography> */}
                                        <Typography variant='body1'>
                                            {votacaoUsuario?.votacao.titulo}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant='h6'>Respondido</Typography>
                                        {votacaoUsuario?.respondido ?
                                            <CheckCircle style={{ color: green[500] }} /> :
                                            <Cancel style={{ color: red[500] }} />}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            onClick={() => setVotacaoUsuario(votacaoUsuario)}
                                        >
                                            Selecionar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        <TablePagination
                            rowsPerPageOptions={[]}
                            rowsPerPage={pageVotacaoUsuario.size ? pageVotacaoUsuario.size : 0}
                            component="div"
                            count={pageVotacaoUsuario.totalElements}
                            page={pageVotacaoUsuario.number}
                            onPageChange={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                        />
                    </> :
                    <Grid container justifyContent='center' alignItems='center'>
                        <Grid item>
                            <Typography variant='body1'>Nenhuma Votação cadastrada...</Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>


        </>
    );
}

export default VotacaoUsuarioTable;