import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchPostAuth
} from '../../util/FetchUtil';

const controller = 'votacao-opcao-resposta/';

export const findVotacaoOpcaoRespostaByVotacaoCodigoAndCurrentUser = (codVotacao) => {
  const url = SERVER_URL + controller + 'query-list?cod-votacao=' + codVotacao + '&current-user=true';
  return fetchGetAuth(url);
}

export const saveVotacaoRespostaList = (votacaoRespostaList) => {
  const url = SERVER_URL + controller + "save-list";
  return fetchPostAuth(url, votacaoRespostaList);
}

