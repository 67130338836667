import { SERVER_URL } from '../../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../../util/FetchUtil';

const controller = 'mural-turma';

export const findByMuralCodigo = (codMural) => {
  const url = SERVER_URL + controller + '/' + codMural;
  return fetchGetAuth(url);
}

export const save = (muralTurma) => {
  const url = SERVER_URL + controller + '/';
  return fetchPostAuth(url, muralTurma);
}

export const deleteById = (codMuralTurma) => {
  const url = SERVER_URL + controller + "/" + codMuralTurma;
  return fetchRemoveAuthReturn(url);
}
