import React, { useEffect, useState } from "react";
import Container from "../../UI/Container";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { findPresencaByData, remove } from "../api-presenca";
import { errorMessage } from "../../../util/ConfirmAlertUtil";
import Loading from "../../UI/Loading";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import DataProfile from "../../UI/DateProfile";
import TextFieldDate from "../../UI/TextFieldDate";
import { dateFormatMoment } from "../../../util/DateUtil";
import PresencaPorDataTable from "./PresencaPorDataTable";

const useStyles = makeStyles({
  paper: {
    padding: "20px",
  },
  title: {
    marginBottom: "20px",
  },
});

const PresencaPorDataContainer = () => {
  const classes = useStyles();
  const [dataCalendar, setDataCalendar] = useState(null);
  const [presencas, setPresencas] = useState({
    content: [],
    size: 0,
    totalElements: 0,
    number: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);


  useEffect(() => {
    if (dataCalendar) {
      setIsLoading(true);
      findPresencaByData(dataCalendar, 0).then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setPresencas(data);
          }
        }
        setIsLoading(false);
      });
    }
  }, [dataCalendar]);

  const handleTrocaDataCalendar = () => {
    setDataCalendar(null);
  };

  const handleChangePage = (event, newPage) => {
    if (dataCalendar) {
      setIsLoading(true);
      findPresencaByData(dataCalendar, newPage).then((data) => {
        if (data) {
          if (data.error) {
            errorMessage(data.message);
          } else {
            setPresencas(data);
          }
        }
        setIsLoading(false);
      });
    }
  };

  const removePresencas = (codPresenca) => {
    setPresencas((prevState) => ({
      ...prevState,
      content: prevState.content.filter((ps) => ps.codigo !== codPresenca),
    }));
  };

  const handleDelete = (codigo) => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar esta presença?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingRemove(true);
            remove(codigo).then((data) => {
              if (data) {
                if (data.error) {
                  errorMessage(data.message);
                } else {
                  removePresencas(codigo);
                  toast.success("Registro excluído com Sucesso.", {
                    position: toast.POSITION.BOTTOM_LEFT,
                  });
                }
              }
              setIsLoadingRemove(false);
            });
          },
        },
        {
          label: "Não",
          onClick: () => { },
        },
      ],
    });
  };

  const handleDataChange = (dataElement) => {
    if (dataElement)
      dataElement.setHours(0, 0, 0, 0);

    setDataCalendar(dataElement);
  }

  return (
    <Container className={classes.paper} component={Paper}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography className={classes.title} variant="h6">
            Presença por Data
          </Typography>
        </Grid>
        {dataCalendar ? (
          <>
            <Grid item>
              <DataProfile
                dataCalendar={dataCalendar}
                handleTrocaDataCalendar={handleTrocaDataCalendar}
              />
            </Grid>
            <Grid item>
              {isLoading ? (
                <Loading description="Carregando Presenças..." />
              ) : (
                <>
                  <PresencaPorDataTable
                    presencas={presencas}
                    handleChangePage={handleChangePage}
                    isLoadingRemove={isLoadingRemove}
                    handleDelete={handleDelete}
                  />
                  {/* {presenca ? (
                    <PresencaTurmaForm
                      presenca={presenca}
                      setPresenca={setPresenca}
                      setPresencas={setPresencas}
                      turma={dataCalendar}
                    />
                  ) : (
                    <PresencaPorDataTable
                      presencas={presencas}
                      handleChangePage={handleChangePage}
                      isLoadingRemove={isLoadingRemove}
                      handleDelete={handleDelete}
                      setPresenca={setPresenca}
                    />
                  )} */}
                </>
              )}
            </Grid>
          </>
        ) : (
          <Grid item>
            <TextFieldDate
              required
              label="Data"
              value={dataCalendar ? dateFormatMoment(dataCalendar) : null}
              onChange={handleDataChange}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default PresencaPorDataContainer;
