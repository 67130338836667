import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import { findVotacaoOpcaoByVotacaoCodigo } from '../VotacaoOpcao/api-votacao-opcao';
import { findVotacaoOpcaoRespostaByVotacaoCodigoAndCurrentUser, saveVotacaoRespostaList } from './api-votacao-opcao-resposta';
import VotacaoOpcaoRespostaTableSelect from './VotacaoOpcaoRespostaTableSelect';
import Loading from '../UI/Loading';

const VotacaoOpcaoRespostaContainer = ({ votacaoUsuario, updateVotacaoUsuario }) => {
    const [listVotacaoOpcao, setListVotacaoOpcao] = useState([]);
    const [listVotacaoOpcaoResposta, setListVotacaoOpcaoResposta] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchDataVotacaoOpcao();
        fetchDataVotacaoOpcaoResposta();
        // eslint-disable-next-line
    }, []);

    const fetchDataVotacaoOpcao = () => {
        setIsLoading(true);
        findVotacaoOpcaoByVotacaoCodigo(votacaoUsuario.votacao.codigo).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setListVotacaoOpcao(data);
                }
            }
            setIsLoading(false);
        })
    }
    const fetchDataVotacaoOpcaoResposta = () => {
        setIsLoading(true);
        findVotacaoOpcaoRespostaByVotacaoCodigoAndCurrentUser(votacaoUsuario.votacao.codigo).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setListVotacaoOpcaoResposta(data);
                }
            }
            setIsLoading(false);
        })
    }

    const handleSalvarResposta = () => {
        const votacaoRespostaList = {
            votacaoUsuario: votacaoUsuario,
            listVOR: listVotacaoOpcaoResposta
        }
        saveVotacaoRespostaList(votacaoRespostaList).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    updateVotacaoUsuario(data.votacaoUsuario);
                }
            }
        })
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {isLoading ? <Loading description="Carregando Opções..." /> :
                    <VotacaoOpcaoRespostaTableSelect
                        votacaoUsuario={votacaoUsuario}
                        listVotacaoOpcao={listVotacaoOpcao}
                        listVotacaoOpcaoResposta={listVotacaoOpcaoResposta}
                        setListVotacaoOpcaoResposta={setListVotacaoOpcaoResposta}
                        handleSalvarResposta={handleSalvarResposta}
                    />
                }

            </Grid>




        </Grid>
    )
}

export default VotacaoOpcaoRespostaContainer;