import { Box, FormControlLabel, FormLabel, Grid, makeStyles, styled, Switch, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Button from '../UI/Button';
import { toast } from 'react-toastify';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import CloseIcon from '@material-ui/icons/Close';
import { removeUploadedImagemVotacaoOpcao, saveVotacaoOpcao, uploadImagemVotacaoOpcao } from './api-votacao-opcao';
import { confirmAlert } from 'react-confirm-alert';
import InputImageByte from '../UI/InputImageByte';

const useStyles = makeStyles({
    send: {
        backgroundColor: '#4caf50'
    },
    closeIcon: {
        marginTop: '20px',
        marginBottom: '10px',
        cursor: 'pointer'
    },

});

const FormGrid = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "column",
}));


const VotacaoOpcaoForm = ({ votacaoOpcao, setVotacaoOpcao, setListVotacaoOpcao }) => {
    const [isPerforming, setIsPerforming] = useState(false);
    const classes = useStyles();
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);

    useEffect(() => {
        if (file) {
            // create the preview
            const objectUrl = URL.createObjectURL(file);
            setPreview(objectUrl);

            // free memory when ever this component is unmounted
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, [file]);

    const handleUploadLogoInit = () => {
        if (file === null) {
            return;
        }

        const fileFormData = new FormData();
        fileFormData.append("file", file);
        handleUploadImagem(fileFormData);
    };


    const handleChangeImage = (e) => {
        setFile(e.target.files[0]);
    };

    const handleRemoveUploadedImagem = () => {
        confirmAlert({
            title: "Alerta",
            message: "Deseja deletar esta imagem?",
            buttons: [
                {
                    label: "Sim",
                    onClick: () => {
                        setIsPerforming(true);
                        removeUploadedImagemVotacaoOpcao(votacaoOpcao.codigo).then((data) => {
                            if (data) {
                                if (data.error) {
                                    errorMessage(data.message);
                                } else {
                                    setVotacaoOpcao(data);
                                    updateListVotacaoOpcao(data);
                                    toast.success("Operação Realizada com Sucesso.");
                                }
                            }
                            setIsPerforming(false);
                        });
                    },
                },
                {
                    label: "Não",
                    onClick: () => { },
                },
            ],
        });
    };

    const handleUploadImagem = (formData) => {
        setIsPerforming(true);
        uploadImagemVotacaoOpcao(votacaoOpcao.codigo, formData).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setVotacaoOpcao(data);
                    updateListVotacaoOpcao(data);
                    toast.success("Operação Realizada com Sucesso.");
                }
            }
            setIsPerforming(false);
        });
    };

    const handleChange = (event) => {
        setVotacaoOpcao({
            ...votacaoOpcao,
            [event.target.name]: event.target.value
        })
    }

    const updateListVotacaoOpcao = (data) => {
        setListVotacaoOpcao((prevState) => (
            prevState.map((ps) => {
                if (ps.codigo === data.codigo) {
                    return data;
                } else {
                    return ps;
                }
            })));
    }



    const handleSave = () => {
        setIsPerforming(true);
        const eNovo = votacaoOpcao.eNovo;
        saveVotacaoOpcao(votacaoOpcao).then(data => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    toast.success("Salvo com sucesso.", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                    if (eNovo) {
                        setListVotacaoOpcao((prevState) => ([data, ...prevState]));
                    } else {
                        updateListVotacaoOpcao(data)
                    }
                    setVotacaoOpcao(data);
                }
            }
            setIsPerforming(false);
        })
    }

    const handleDelete = () => {

    }

    const handleSwitchPossuiMaxEscolhaChange = (event) => {
        setVotacaoOpcao((prevState) => ({
            ...prevState,
            possuiMaxEscolha: event.target.checked,
        }));
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <CloseIcon
                    onClick={() => setVotacaoOpcao(null)}
                    className={classes.closeIcon}
                    fontSize='small'
                />
            </Grid>
            <>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        size='small'
                        required
                        multiline
                        minRows={4}
                        fullWidth
                        label="Descrição"
                        name="descricao"
                        onChange={handleChange}
                        inputProps={{ maxLength: 2000 }}
                        value={votacaoOpcao && votacaoOpcao.descricao ? votacaoOpcao.descricao : ''}
                    />
                </Grid>
                {votacaoOpcao.eNovo === null || !votacaoOpcao.eNovo ?
                    <Grid item xs={12}>
                        <Box component="section" sx={{ p: 2, border: "1px dashed grey" }}>
                            <FormGrid item xs={4}>
                                <FormLabel>Image</FormLabel>
                                {votacaoOpcao !== null && votacaoOpcao.urlImagem !== null &&
                                    votacaoOpcao.urlImagem !== "" ? (
                                    <>
                                        <img
                                            style={{
                                                width: "200px",
                                            }}
                                            src={votacaoOpcao.urlImagem}
                                            alt=""
                                        />
                                        <Button
                                            isLoadingAction={isPerforming}
                                            style={{ marginTop: "10px" }} onClick={handleRemoveUploadedImagem}
                                            color="secondary"
                                        >
                                            Remover Imagem
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <FormLabel htmlFor="documentFile">
                                            Selecione a Imagem
                                        </FormLabel>

                                        <InputImageByte
                                            id="documentFile"
                                            name="documentFile"
                                            image={preview}
                                            handleChangeImage={handleChangeImage}
                                            multiple={false}
                                        />
                                        {file && (
                                            <Button
                                                style={{ marginTop: "10px" }}
                                                disabled={isPerforming}
                                                color="secondary"
                                                onClick={handleUploadLogoInit}
                                            >
                                                Upload Imagem
                                            </Button>
                                        )}
                                    </>
                                )}

                            </FormGrid>
                        </Box>
                    </Grid> :
                    <></>
                }
                <Grid item xs={4}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={votacaoOpcao && votacaoOpcao.possuiMaxEscolha ? true : false}
                                onChange={handleSwitchPossuiMaxEscolhaChange}
                                value={votacaoOpcao && votacaoOpcao.possuiMaxEscolha ? true : false}
                            />
                        }
                        label="Possui Máximo de Escolhas"
                    />
                </Grid>
                {votacaoOpcao && votacaoOpcao.possuiMaxEscolha &&
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            size='small'
                            label="Quantidade Máximo Escolha"
                            name="quatMaxEscolha"
                            type='number'
                            onChange={handleChange}
                            value={votacaoOpcao && votacaoOpcao.quatMaxEscolha ? votacaoOpcao.quatMaxEscolha : ''}
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={isPerforming}
                                onClick={handleSave}
                            >
                                Salvar
                            </Button>
                        </Grid>
                        {!votacaoOpcao.eNovo &&
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={isPerforming}
                                    onClick={handleDelete}
                                >
                                    Deletar
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </>

        </Grid>
    )
}

export default VotacaoOpcaoForm;