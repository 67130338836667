import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import VotacaoTable from './VotacaoTable';
import { findVotacaoPage } from './api-votacao';
import Container from '../UI/Container';
import Loading from '../UI/Loading';
import VotacaoForm from './VotacaoForm';

const VotacaoContainer = () => {

    const [pageVotacao, setPageVotacao] = useState({
        content: [],
        size: 0,
        number: 0,
        totalElements: 0
    });
    const [votacao, setVotacao] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchDataVotacao(0);
    }, []);

    const fetchDataVotacao = (newPage) => {
        setIsLoading(true);
        findVotacaoPage(newPage).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setPageVotacao(data);
                }
            }
            setIsLoading(false);
        })
    }

    const handleChangePage = (event, newPage) => {
        fetchDataVotacao(newPage);

    };

    return (
        <Container >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Votação
                    </Typography>
                </Grid>

                {votacao ?
                    <Grid item xs={12}>
                        <VotacaoForm
                            votacao={votacao}
                            setVotacao={setVotacao}
                            setPageVotacao={setPageVotacao}
                        />
                    </Grid> :
                    <Grid item xs={12}>
                        {isLoading ? <Loading description="Carregando votações..." /> :
                            <VotacaoTable
                                pageVotacao={pageVotacao}
                                handleChangePage={handleChangePage}
                                setVotacao={setVotacao}
                            />
                        }

                    </Grid>

                }


            </Grid>
        </Container>
    )
}

export default VotacaoContainer;