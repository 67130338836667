import { SERVER_URL } from '../../constants';
import {
  fetchGetAuth,
  fetchPostAuth,
  fetchRemoveAuth
} from '../../util/FetchUtil';

const controller = 'votacao-aluno/';

const findByVotacao = (codVotacao) => {
  const url = SERVER_URL + controller + 'votacao/' + codVotacao;
  return fetchGetAuth(url);
}

const save = (votacaoAluno) => {
  const url = SERVER_URL + controller;
  return fetchPostAuth(url, votacaoAluno);
}

const remove = (codVotacao) => {
  const url = SERVER_URL + controller + codVotacao;
  return fetchRemoveAuth(url);
}

export {
  findByVotacao,
  save,
  remove,
}